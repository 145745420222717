import { useState, useEffect } from 'react';
import FeedSource from '../FeedSource';
import '../Styles/MonitorFeed.css';
import { useBackend } from '../../Hooks/UseBackend';
import { Feed, SoundStatus } from '../../post-interface';
import { Post, Source } from '@sodamnfoolish/sjc-backend-types/src/api/feed/dto';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GeneralPosts } from '../GeneralPosts';

import { useFeedGeteway } from '../../Hooks/UseFeedGateway';
import { Api } from '@sodamnfoolish/sjc-backend-types';
import { SOUND } from '../../utils/source-constants';
import InteractionBanner from '../Banner';

export function MonitorFeed({
    sources,
    setSources,
    isGeneralPostsVisible,
    tweetTypes,
    updateTweetTypes,
}: {
    sources: Source[];
    setSources: React.Dispatch<React.SetStateAction<Source[]>>;
    isGeneralPostsVisible: boolean;
    tweetTypes: {
        [key: string]: {
            selectedTweetTypes: Set<string>;
            soundTweetTypes: Set<string>;
        };
    };
    updateTweetTypes: (username: string, newSelectedTweetTypes: Set<string>, newSoundTweetTypes: Set<string>) => void;
}) {
    const { getFeed } = useBackend();
    const { feedGatewayClient } = useFeedGeteway();

    const [generalFeed, setGeneralFeed] = useState<Feed>({});
    const [feeds, setFeeds] = useState<Feed>({});
    const [soundsEnabled, setSoundsEnabled] = useState<SoundStatus>(() => {
        const savedSoundStatus = localStorage.getItem('soundsEnabled');
        return savedSoundStatus ? JSON.parse(savedSoundStatus) : {};
    });
    const [highlightedPosts, setHighlightedPosts] = useState<Set<string>>(new Set());

    useEffect(() => {
        const fetchFeeds = async () => {
            sources.forEach(async (source) => {
                const posts = await getFeed([source]);

                setFeeds((prevFeeds) => ({
                    ...prevFeeds,
                    [source.username]: posts,
                }));
            });
        };

        if (sources.length > 0) fetchFeeds();
    }, [sources]);

    useEffect(() => {
        if (!feedGatewayClient) return;

        async function playSound(post: Post) {
            const getDetails = <T extends Record<string, any>>(base: Record<string, T>, platform: string) => {
                return base[platform] && (base[platform][post.username as keyof T] as string);
            };
            const sound = getDetails(SOUND, post.platform);
            if (post.tweetType && !tweetTypes[post.username].soundTweetTypes.has(post.tweetType)) {
                return;
            }

            if (sound) {
                try {
                    const audio = new Audio(sound);
                    audio.play().catch((error) => {
                        console.error('Error playing sound:', error);
                        toast.error('Unable to play sound. Please interact with the page first.');
                    });
                } catch (error) {
                    console.error('Error initializing sound play:', error);
                    toast.error('Unable to play sound. Please interact with the page first.');
                }
            }
        }
        feedGatewayClient.on(Api.Feed.Gateway.Event.NewPost, async (post: Api.Feed.DTO.Post) => {
            try {
                const newFeeds = { ...feeds };
                const feed: Post[] = newFeeds[post.username] || [];
                feed.unshift(post);
                feed.pop();
                newFeeds[post.username] = feed;
                setFeeds(newFeeds);

                const newGeneralFeed = { ...generalFeed };
                const generalPosts = newGeneralFeed.Everyone || [];
                generalPosts.unshift(post);
                generalPosts.pop();
                newGeneralFeed.Everyone = generalPosts;
                setGeneralFeed(newGeneralFeed);

                if (soundsEnabled[post.username]) {
                    playSound(post);
                }

                setHighlightedPosts((prev) => {
                    const updated = new Set(prev);
                    updated.add(post.id);
                    return updated;
                });

                setTimeout(() => {
                    setHighlightedPosts((prev) => {
                        const updated = new Set(prev);
                        updated.delete(post.id);
                        return updated;
                    });
                }, 60000);
            } catch (err) {
                console.error(err);
            }
        });

        feedGatewayClient.on(Api.Feed.Gateway.Event.PostUpdate, async (update: Api.Feed.DTO.Post) => {
            try {
                const newFeeds = { ...feeds };
                const feed = newFeeds[update.username] || [];
                const index = feed.findIndex((p) => p.id === update.id);
                let post = feed[index];
                post = { ...post, ...update };
                feed[index] = post;
                newFeeds[update.username] = feed;
                setFeeds(newFeeds);

                const newGeneralFeed = { ...generalFeed };
                const generalPosts = newGeneralFeed.Everyone || [];
                const generalIndex = generalPosts.findIndex((p) => p.id === update.id);
                let generalPost = generalPosts[generalIndex];
                generalPost = { ...generalPost, ...update };
                generalPosts[generalIndex] = generalPost;
                newGeneralFeed.Everyone = generalPosts;
                setGeneralFeed(newGeneralFeed);

                if (soundsEnabled[update.username]) {
                    playSound(post);
                }
            } catch (err) {
                console.error(err);
            }
        });

        feedGatewayClient.on(Api.Feed.Gateway.Event.UnregisterSource, (source: Api.Feed.DTO.Source) => {
            console.log(Event.name);
            try {
                console.log('Source maintenance stopped', source);
                const newSources = sources.filter((s) => s !== source);
                setSources(newSources);
                toast.error(`Source maintenance stopped ${source.username}`);
            } catch (err) {
                console.error(err);
                toast.error(`Failed to handle Source Maintanance Stopped event`);
            }
        });

        feedGatewayClient.on(Api.Feed.Gateway.Event.RegisterSource, (source: Api.Feed.DTO.Source) => {
            try {
                const newSources = [...sources, source];
                setSources(newSources);
                console.log('Source maintenance started', source);
                toast.success(`Source maintenance started ${source.username}`);
            } catch (err) {
                console.error(err);
                toast.error(`Failed to handle Source Maintanance Started event`);
            }
        });

        return () => {
            feedGatewayClient.off(Api.Feed.Gateway.Event.NewPost);
            feedGatewayClient.off(Api.Feed.Gateway.Event.PostUpdate);
            feedGatewayClient.off(Api.Feed.Gateway.Event.UnregisterSource);
            feedGatewayClient.off(Api.Feed.Gateway.Event.RegisterSource);
        };
    }, [sources, feedGatewayClient, feeds, soundsEnabled, SOUND, tweetTypes]);

    useEffect(() => {
        localStorage.setItem('soundsEnabled', JSON.stringify(soundsEnabled));
    }, [soundsEnabled]);

    return (
        <section>
            <div className='body'>
                <InteractionBanner />
                {isGeneralPostsVisible && (
                    <GeneralPosts generalFeed={generalFeed} setGeneralFeed={setGeneralFeed} highlightedPosts={highlightedPosts} visibleSources={sources} />
                )}
                {sources.map((source) => {
                    const sourceTweetTypes = tweetTypes[source.username];
                    return (
                        <div key={source.username}>
                            {sourceTweetTypes ? (
                                <FeedSource
                                    posts={feeds[source.username] || []}
                                    source={source}
                                    soundsEnabled={soundsEnabled}
                                    setSoundsEnabled={setSoundsEnabled}
                                    highlightedPosts={highlightedPosts}
                                    soundTweetTypes={tweetTypes[source.username].soundTweetTypes}
                                    selectedTweetTypes={tweetTypes[source.username].selectedTweetTypes}
                                    updateTweetTypes={updateTweetTypes}
                                />
                            ) : (
                                <div>Loading tweet types...</div>
                            )}
                        </div>
                    );
                })}
            </div>
        </section>
    );
}

export default MonitorFeed;
