import axios from 'axios';
import { Source, Post } from '@sodamnfoolish/sjc-backend-types/src/api/feed/dto';
import { Api } from '@sodamnfoolish/sjc-backend-types';
export class ParserBackend {
    constructor(private readonly url: string, private readonly apiKey: string) {}

    public getSources = (): Promise<Source[]> =>
        axios
            .get(`${this.url}/feed/sources`, {
                headers: {
                    'x-api-key': this.apiKey,
                },
            })
            .then((res) => res.data);

    public getFeed = (sources: Source[], size = 20): Promise<Post[]> =>
        axios
            .get(`${this.url}/feed/`, {
                headers: {
                    'x-api-key': this.apiKey,
                },
                params: {
                    sources: JSON.stringify(sources),
                    size: size,
                },
            })
            .then((res) => {
                if (sources.length > 1) {
                    console.log('GENERAL HERE');
                    console.log('stringify', JSON.stringify([...sources]));
                    console.log(res.data);
                }
                return res.data;
            });

    public getMedia = (mediaId: string): Promise<Blob> =>
        axios
            .get(`${this.url}/feed/media/${mediaId}`, {
                headers: {
                    'x-api-key': this.apiKey,
                },
                responseType: 'blob',
            })
            .then((res) => res.data);
}
