import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export function useUrlParams() {
    const location = useLocation();
    const [sessionId, setSessionId] = useState<string>('0');

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const sessionIdParam = urlParams.get('sessionId');
        setSessionId(sessionIdParam || '0');
    }, [location.search]);

    return { sessionId };
}
