import './styles.css';
import { Navbar } from './Components/Navbar';
import { MonitorFeed } from './Components/pages/MonitorFeed';
import { Route, Routes, useLocation } from 'react-router-dom';
import { TokenCreate } from './Components/pages/TokenCreate';
import { Header } from './Components/Header';
import { useEffect, useState } from 'react';
import { useBackend } from './Hooks/UseBackend';
import { Source } from '@sodamnfoolish/sjc-backend-types/src/api/feed/dto';
import { ToastContainer } from 'react-toastify';

function App() {
    const [sources, setSources] = useState<Source[]>([]);
    const [visibleSources, setVisibleSources] = useState<Source[]>([]);
    const [isGeneralPostsVisible, setGeneralPostsVisible] = useState(true);
    const location = useLocation();
    const isTokenCreatePage = location.pathname === '/TokenCreate';

    const { getSources } = useBackend();

    const [tweetTypes, setTweetTypes] = useState<{
        [key: string]: { selectedTweetTypes: Set<string>; soundTweetTypes: Set<string> };
    }>(() => {
        const initialTweetTypes: {
            [key: string]: { selectedTweetTypes: Set<string>; soundTweetTypes: Set<string> };
        } = {};

        sources.forEach((source) => {
            const selectedSaved = localStorage.getItem(`selectedTweetTypes_${source.username}`);
            const soundSaved = localStorage.getItem(`soundTweetTypes_${source.username}`);

            initialTweetTypes[source.username] = {
                selectedTweetTypes: selectedSaved ? new Set(JSON.parse(selectedSaved)) : new Set(['tweet', 'retweet', 'reply']),
                soundTweetTypes: soundSaved ? new Set(JSON.parse(soundSaved)) : new Set(['tweet', 'retweet', 'reply']),
            };
        });
        return initialTweetTypes;
    });

    const updateTweetTypes = (username: string, newSelectedTweetTypes: Set<string>, newSoundTweetTypes: Set<string>) => {
        console.log('username', username);
        console.log('enabled sound types', newSoundTweetTypes);

        setTweetTypes((prevTweetTypes) => ({
            ...prevTweetTypes,
            [username]: {
                selectedTweetTypes: newSelectedTweetTypes,
                soundTweetTypes: newSoundTweetTypes,
            },
        }));

        localStorage.setItem(`selectedTweetTypes_${username}`, JSON.stringify([...newSelectedTweetTypes]));
        localStorage.setItem(`soundTweetTypes_${username}`, JSON.stringify([...newSoundTweetTypes]));
    };

    useEffect(() => {
        getSources().then((data) => {
            console.log('sources', data);
            setSources(data);
            setVisibleSources(data);

            setTweetTypes((prevTweetTypes) => {
                const newTweetTypes = { ...prevTweetTypes };

                data.forEach((source) => {
                    if (!newTweetTypes[source.username]) {
                        const selectedSaved = localStorage.getItem(`selectedTweetTypes_${source.username}`);
                        const soundSaved = localStorage.getItem(`soundTweetTypes_${source.username}`);

                        newTweetTypes[source.username] = {
                            selectedTweetTypes: selectedSaved ? new Set(JSON.parse(selectedSaved)) : new Set(['tweet', 'retweet', 'reply']),
                            soundTweetTypes: soundSaved ? new Set(JSON.parse(soundSaved)) : new Set(['tweet', 'retweet', 'reply']),
                        };
                    }
                });

                return newTweetTypes;
            });
        });
    }, []);

    const handleToggleSource = (source: Source, checked: boolean) => {
        if (checked) {
            setVisibleSources((prev) => [...prev, source]);
        } else {
            setVisibleSources((prev) => prev.filter((s) => s.username !== source.username));
        }
    };

    const visibleGeneralPosts = (checked: boolean) => {
        setGeneralPostsVisible(checked);
    };

    return (
        <div className='container'>
            <section className={`header ${isTokenCreatePage ? 'token-create-header' : ''}`}>
                <Navbar />
                <div className='inputs'>
                    <div className='dyn-inputs'>
                        <label className={`custom-checkbox ${isGeneralPostsVisible ? 'checked' : ''}`}>
                            <span className='label-text'>Everyone</span>
                            <input type='checkbox' checked={isGeneralPostsVisible} onChange={(e) => visibleGeneralPosts(e.target.checked)} />
                            <span className='custom-checkbox-box'>
                                <span className='checkmark'>✔</span>
                                <span className='cross'>✖</span>
                            </span>
                        </label>
                    </div>
                    {sources.map((source, index) => (
                        <Header
                            key={index}
                            source={source}
                            onToggle={handleToggleSource}
                            isChecked={visibleSources.some((s) => s.username === source.username)}
                        />
                    ))}
                </div>
            </section>
            <section>
                <Routes>
                    <Route
                        path='/'
                        element={
                            <MonitorFeed
                                sources={visibleSources}
                                setSources={setSources}
                                isGeneralPostsVisible={isGeneralPostsVisible}
                                tweetTypes={tweetTypes}
                                updateTweetTypes={updateTweetTypes}
                            />
                        }
                    />
                    <Route path='TokenCreate' element={<TokenCreate />} />
                </Routes>
            </section>
            <section className='toast-cont'>
                <ToastContainer position='bottom-right' theme='dark' />
            </section>
        </div>
    );
}

export default App;
