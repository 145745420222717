import './Styles/MessageCard.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Twemoji } from 'react-emoji-render';
import { ExternalMedia, InternalMedia, Media, Post } from '@sodamnfoolish/sjc-backend-types/src/api/feed/dto';
import { useUrlParams } from './pages/useUrlParams';
import { useEffect, useState } from 'react';
import { useBackend } from '../Hooks/UseBackend';
import { MediaSource } from '@sodamnfoolish/sjc-backend-types/src/api/feed/media-source';

const MessageCard = ({
    post,
    background,
    onImageClick,
    platform,
    username,
    isGeneralPost,
    icon,
    className,
}: {
    post: Post;
    background: string;
    onImageClick?: (index: number) => void;
    platform: string;
    username?: string;
    isGeneralPost?: boolean;
    icon?: string;
    className?: string;
}) => {
    const { sessionId } = useUrlParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { getMedia } = useBackend();
    const [imageSrcs, setImageSrcs] = useState<string[]>([]);
    const isOnTokenCreate = location.pathname === '/TokenCreate';

    useEffect(() => {
        const fetchImages = async () => {
            if (post.media && post.media.length > 0) {
                const mediaPromises = post.media.map(async (mediaItem: Media) => {
                    if (mediaItem.source === MediaSource.Internal) {
                        try {
                            const blob = await getMedia((mediaItem as InternalMedia).id);

                            return URL.createObjectURL(blob);
                        } catch (error) {
                            console.error('Error fetching internal media:', error);
                            return null;
                        }
                    } else if (mediaItem.source === MediaSource.External) {
                        return (mediaItem as ExternalMedia).url;
                    }
                });

                const images = await Promise.all(mediaPromises);
                setImageSrcs(images.filter(Boolean) as string[]);
            }
        };

        fetchImages();
    }, []);

    const formatTime = (date: Date) => {
        const day = date.getDate();
        const year = date.getFullYear();
        const hours24 = date.getHours();
        const hours12 = hours24 % 12 || 12;
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const amPm = hours24 >= 12 ? 'PM' : 'AM';
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = months[date.getMonth()];
        return `${hours12}:${minutes} ${amPm} - ${month} ${day}, ${year}`;
    };

    const displayText = (text: string, fullstoryUrl: string | null) => {
        if (!text) return null;

        const emoji = /:(0|1|2|3|4|5|6|7|8|9|10):/g;
        text = text.replaceAll('*', '');

        const fullStoryParts = text.split('Full Story');

        return fullStoryParts.map((fullStoryPart, fsIndex) => {
            const parts = fullStoryPart.split(emoji);

            return parts
                .map((part, index) => {
                    const trimmedPart = part.trim();
                    if (index % 2 !== 0) {
                        return <span key={`${fsIndex}-${index}`}>:{part}:</span>;
                    }

                    return trimmedPart ? (
                        <span key={`${fsIndex}-${index}`}>
                            <Twemoji text={trimmedPart} />
                        </span>
                    ) : null;
                })
                .concat(
                    fsIndex < fullStoryParts.length - 1 && fullstoryUrl ? (
                        <a href={fullstoryUrl} target='_blank' rel='noopener noreferrer' key={`fullstory-${fsIndex}`}>
                            Full Story
                            <p></p>
                        </a>
                    ) : null,
                );
        });
    };

    return (
        <section>
            <div className={`mes-card ${className}`} style={{ background }}>
                <div className='username-icon'>
                    {isGeneralPost && username && (
                        <div className='username-container'>
                            {icon && <img src={`data:image/jpeg;base64,${icon}`} className='channel-icon' />}
                            <h3 className='username'>{username}</h3>
                        </div>
                    )}
                </div>
                <div className='info'>
                    <h3>{post.text && displayText(post.text, post.fullStoryUrl)}</h3>
                </div>
                <div className='img-section'>
                    {imageSrcs.length > 0 ? (
                        imageSrcs.map((src, index) => (
                            <img
                                key={index}
                                className={`post-img ${imageSrcs.length === 1 ? 'single-img' : 'multiple-img'}`}
                                src={src}
                                alt='Post Image'
                                onClick={() => onImageClick && onImageClick(index)}
                            />
                        ))
                    ) : (
                        <p></p>
                    )}
                </div>
                <div className='bottom-section'>
                    <div className='platf-type'>
                        {username !== 'WatcherGuru' && username !== 'gdhdhdhdhgf' && (
                            <div className='tweet-type'>
                                <h3 className={`tweet-type-text ${post.tweetType?.toLowerCase() || 'unknown'}`}></h3>
                            </div>
                        )}
                        <div className='dat-platf'>
                            <div className='platf-btn'>
                                <a
                                    href={post.url ? post.url : '#'}
                                    target='_blank'
                                    onClick={(e) => {
                                        if (!post.url) {
                                            e.preventDefault();
                                            console.log('URL not provided');
                                        }
                                    }}
                                >
                                    <h3>View On {username === 'elonalert' ? 'twitter' : platform}</h3>
                                </a>
                            </div>
                            <h3>{formatTime(new Date(post.date))}</h3>
                        </div>
                    </div>
                    {!isOnTokenCreate && (
                        <div className={`link-btn ${post.tweetType === 'tweet' ? 'tweet-button' : ''}`}>
                            <button
                                className='link'
                                onClick={() => {
                                    navigate(`TokenCreate?sessionId=${sessionId}`, { state: { post: post, background, platform } });
                                    console.log('Navigating with post data:', post);
                                }}
                            >
                                <span className='span-link'>&#8250;</span>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default MessageCard;
