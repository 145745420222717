import MessageCard from './MessageCard';
import './Styles/FeedSource.css';
import { SoundStatus } from '../post-interface';
import { Post, Source } from '@sodamnfoolish/sjc-backend-types/src/api/feed/dto';
import { useEffect, useRef, useState } from 'react';
import { IMAGE, COLOR, URL } from '../utils/source-constants';

const FeedSource = ({
    posts,
    source,
    soundsEnabled,
    setSoundsEnabled,
    highlightedPosts,
    selectedTweetTypes,
    soundTweetTypes,
    updateTweetTypes,
}: {
    posts: Post[];
    source: Source;
    soundsEnabled: SoundStatus;
    setSoundsEnabled: React.Dispatch<React.SetStateAction<SoundStatus>>;
    highlightedPosts: Set<string>;
    selectedTweetTypes: Set<string>;
    soundTweetTypes: Set<string>;
    updateTweetTypes: (username: string, newSelectedTweetTypes: Set<string>, newSoundTweetTypes: Set<string>) => void;
}) => {
    const { platform, username } = source;
    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement | null>(null);

    const channelsWithoutMenu = new Set(['WatcherGuru', 'gdhdhdhdhgf']);

    const toggleMenu = () => {
        setMenuOpen((prev) => !prev);
    };
    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        if (menuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuOpen]);

    useEffect(() => {
        localStorage.setItem(`selectedTweetTypes_${username}`, JSON.stringify(Array.from(selectedTweetTypes)));
    }, [selectedTweetTypes, username]);

    useEffect(() => {
        localStorage.setItem(`soundTweetTypes_${username}`, JSON.stringify(Array.from(soundTweetTypes)));
    }, [soundTweetTypes, username]);

    function getSourceDetails() {
        const getDetails = <T extends Record<string, any>>(base: Record<string, T>, platform: string) => {
            return base[platform] && (base[platform][username as keyof T] as string);
        };

        const color = getDetails(COLOR, platform);
        const image = getDetails(IMAGE, platform);
        const url = getDetails(URL, platform);

        return { color, image, url };
    }

    const { color, image, url } = getSourceDetails();

    const gradients = getComputedStyle(document.body);
    const currentGradient = gradients.getPropertyValue(color);
    const filteredPosts = !channelsWithoutMenu.has(source.username)
        ? posts.filter((post) => post.tweetType && typeof post.tweetType === 'string' && selectedTweetTypes.has(post.tweetType))
        : posts;

    return (
        <section>
            <div className='header-title'></div>
            <div className='container-form'>
                <div className={`feed-source ${source.username === 'realDonaldTrump' ? 'realDonaldTrump' : ''}`}>
                    <div className='icon-naming-link'>
                        <img className='icon' src={`data:image/jpeg;base64,${image}`} />
                        <div className='name-url'>
                            <h3>{source.username}</h3>
                            <a
                                className='link-to-channel'
                                href={url ? url : '#'}
                                target='_blank'
                                onClick={(e) => {
                                    if (!url) {
                                        e.preventDefault();
                                        console.log('URL not provided');
                                    }
                                }}
                            >
                                <h3>{url && url.includes('https://') ? url.slice(8) : url}</h3>
                            </a>
                        </div>
                    </div>
                    <div
                        className={`volume-check ${soundsEnabled[source.username] ? 'checked' : ''}`}
                        onClick={() => {
                            const newSoundsEnabled = { ...soundsEnabled };
                            newSoundsEnabled[source.username] = !newSoundsEnabled[source.username];
                            setSoundsEnabled(newSoundsEnabled);
                        }}
                    >
                        <div className='checkbox-icon'></div>
                    </div>
                    <div className='more-options'>
                        {!channelsWithoutMenu.has(source.username) && (
                            <>
                                <button onClick={toggleMenu} className='settings-button'>
                                    <span className='settings-icon' role='img' aria-label='settings'></span>
                                </button>
                                {menuOpen && (
                                    <div ref={menuRef} className='settings-menu'>
                                        <div className='menu-section'>
                                            <h4>DISPLAY</h4>
                                            <label>
                                                <input
                                                    type='checkbox'
                                                    checked={selectedTweetTypes.has('tweet')}
                                                    onChange={() => {
                                                        const newSelectedTweetTypes = new Set(selectedTweetTypes);

                                                        if (newSelectedTweetTypes.has('tweet')) {
                                                            newSelectedTweetTypes.delete('tweet');
                                                        } else {
                                                            newSelectedTweetTypes.add('tweet');
                                                        }

                                                        updateTweetTypes(source.username, newSelectedTweetTypes, soundTweetTypes);
                                                    }}
                                                />{' '}
                                                Tweets
                                            </label>
                                            <label>
                                                <input
                                                    type='checkbox'
                                                    checked={selectedTweetTypes.has('retweet')}
                                                    onChange={() => {
                                                        const newSelectedTweetTypes = new Set(selectedTweetTypes);

                                                        if (newSelectedTweetTypes.has('retweet')) {
                                                            newSelectedTweetTypes.delete('retweet');
                                                        } else {
                                                            newSelectedTweetTypes.add('retweet');
                                                        }

                                                        updateTweetTypes(source.username, newSelectedTweetTypes, soundTweetTypes);
                                                    }}
                                                />{' '}
                                                Retweets
                                            </label>
                                            <label>
                                                <input
                                                    type='checkbox'
                                                    checked={selectedTweetTypes.has('reply')}
                                                    onChange={() => {
                                                        const newSelectedTweetTypes = new Set(selectedTweetTypes);

                                                        if (newSelectedTweetTypes.has('reply')) {
                                                            newSelectedTweetTypes.delete('reply');
                                                        } else {
                                                            newSelectedTweetTypes.add('reply');
                                                        }

                                                        updateTweetTypes(source.username, newSelectedTweetTypes, soundTweetTypes);
                                                    }}
                                                />{' '}
                                                Replies
                                            </label>
                                        </div>
                                        <div className='menu-section'>
                                            <h4>SOUND</h4>
                                            <label>
                                                <input
                                                    type='checkbox'
                                                    checked={soundTweetTypes.has('tweet')}
                                                    onChange={() => {
                                                        const newSoundTweetTypes = new Set(soundTweetTypes);

                                                        if (newSoundTweetTypes.has('tweet')) {
                                                            newSoundTweetTypes.delete('tweet');
                                                        } else {
                                                            newSoundTweetTypes.add('tweet');
                                                        }

                                                        updateTweetTypes(source.username, selectedTweetTypes, newSoundTweetTypes);
                                                    }}
                                                />{' '}
                                                Tweets
                                            </label>
                                            <label>
                                                <input
                                                    type='checkbox'
                                                    checked={soundTweetTypes.has('retweet')}
                                                    onChange={() => {
                                                        const newSoundTweetTypes = new Set(soundTweetTypes);

                                                        if (newSoundTweetTypes.has('retweet')) {
                                                            newSoundTweetTypes.delete('retweet');
                                                        } else {
                                                            newSoundTweetTypes.add('retweet');
                                                        }

                                                        updateTweetTypes(source.username, selectedTweetTypes, newSoundTweetTypes);
                                                    }}
                                                />{' '}
                                                Retweets
                                            </label>
                                            <label>
                                                <input
                                                    type='checkbox'
                                                    checked={soundTweetTypes.has('reply')}
                                                    onChange={() => {
                                                        const newSoundTweetTypes = new Set(soundTweetTypes);

                                                        if (newSoundTweetTypes.has('reply')) {
                                                            newSoundTweetTypes.delete('reply');
                                                        } else {
                                                            newSoundTweetTypes.add('reply');
                                                        }

                                                        updateTweetTypes(source.username, selectedTweetTypes, newSoundTweetTypes);
                                                    }}
                                                />{' '}
                                                Replies
                                            </label>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className='form'>
                    {filteredPosts ? (
                        filteredPosts.map((post) => {
                            return (
                                <MessageCard
                                    post={post}
                                    key={post.id}
                                    background={currentGradient}
                                    platform={platform}
                                    className={highlightedPosts.has(post.id) ? 'highlight' : ''}
                                    username={source.username}
                                />
                            );
                        })
                    ) : (
                        <div>Loading...</div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default FeedSource;
