import { useState, useEffect } from 'react';
import { useBackend } from '../Hooks/UseBackend';
import { Feed } from '../post-interface';
import MessageCard from './MessageCard';
import { Api } from '@sodamnfoolish/sjc-backend-types';
import './Styles/GeneralPosts.css';
import { COLOR, IMAGE, URL } from '../utils/source-constants';

export function GeneralPosts({
    visibleSources,
    generalFeed,
    setGeneralFeed,
    highlightedPosts,
}: {
    visibleSources: Api.Feed.DTO.Source[];
    generalFeed: Feed;
    setGeneralFeed: React.Dispatch<React.SetStateAction<Feed>>;
    highlightedPosts: Set<string>;
}) {
    const { getFeed } = useBackend();

    useEffect(() => {
        const fetchFeeds = async () => {
            try {
                const posts = await getFeed([...visibleSources]);
                const newFeed: Feed = { Everyone: posts };

                setGeneralFeed(newFeed);
            } catch (err) {
                console.error('Error fetching general posts:', err);
            }
        };

        fetchFeeds();
    }, [visibleSources]);

    const username = 'Everyone';

    return (
        <section>
            <div className='header-title'></div>
            <div className='container-form'>
                <div className='feed-source'>
                    <div className='name'>
                        <h3>{username}</h3>
                    </div>
                </div>
                <div className='form'>
                    {generalFeed.Everyone && generalFeed.Everyone.length > 0 ? (
                        generalFeed.Everyone.map((post, index) => {
                            const { platform, username } = post;

                            function getSourceDetails() {
                                const getDetails = <T extends Record<string, any>>(base: Record<string, T>, platform: string) => {
                                    return base[platform] && (base[platform][username as keyof T] as string);
                                };

                                const color = getDetails(COLOR, platform);
                                const image = getDetails(IMAGE, platform);
                                const url = getDetails(URL, platform);

                                return { color, image, url };
                            }

                            const { color, image } = getSourceDetails();

                            const gradients = getComputedStyle(document.body);
                            const currentGradient = gradients.getPropertyValue(color);

                            return (
                                <MessageCard
                                    post={post}
                                    key={index}
                                    background={currentGradient}
                                    platform={platform}
                                    username={username}
                                    isGeneralPost={true}
                                    icon={image}
                                    className={highlightedPosts.has(post.id) ? 'highlight' : ''}
                                />
                            );
                        })
                    ) : (
                        <div className='load'>Loading...</div>
                    )}
                </div>
            </div>
        </section>
    );
}
