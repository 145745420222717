import { Source } from '@sodamnfoolish/sjc-backend-types/src/api/feed/dto';
import { ParserBackend } from '../utils/parser-backend';

export const useBackend = () => {
    const parserBackend = new ParserBackend(process.env.REACT_APP_BACKEND_URL!, process.env.REACT_APP_BACKEND_APIKEY!);

    const getSources = async () => {
        const sources = await parserBackend.getSources();
        return sources;
    };

    const getFeed = async (sources: Source[], size = 20) => {
        const posts = await parserBackend.getFeed(sources, size);
        return posts;
    };

    const getMedia = async (id: string) => {
        const media = await parserBackend.getMedia(id);
        return media;
    };

    return { getSources, getFeed, getMedia };
};
